import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import LineChart from '../components/LineChart';
import BarChart from '../components/BarChart';
import { useWebSocket } from '../contexts/WebSocketContext';

function Asset() {
    const { asset } = useParams();
    const { ws, sendMessage, isConnected } = useWebSocket();
    const [historicalData, setHistoricalData] = useState([]);

  useEffect(() => {
    // Fetch historical data when component mounts
    const fetchHistoricalData = async () => {
      try {
        const response = await fetch(`https://prices.now/historical_prices/${asset}`); // Replace 1 with actual asset ID
        const data = await response.json();

        // Transform the data and remove duplicates using a Map
        const timestampMap = new Map();
        
        data.forEach(item => {
          const timestamp = item[5]; // timestamp
          if (!timestampMap.has(timestamp)) {
            timestampMap.set(timestamp, {
              time: timestamp,
              open: item[1],
              high: item[2],
              low: item[3],
              close: item[4]
            });
          }
        });

        // Convert Map back to array and sort by timestamp
        const formattedData = Array.from(timestampMap.values())
          .sort((a, b) => a.time - b.time);

        setHistoricalData(formattedData);
      } catch (error) {
        console.error('Error fetching historical data:', error);
      }
    };

    fetchHistoricalData();

    // Try to subscribe when connected
    const trySubscribe = () => {
      if (isConnected) {
        const success = sendMessage({
          type: 'subscribe_bars',
          asset_id: asset
        });
        
        if (success) {
          console.log('Successfully subscribed to asset', asset);
        } else {
          console.log('Failed to subscribe, will retry...');
          // Retry after a short delay
          setTimeout(trySubscribe, 250);
        }
      }
    };

    trySubscribe();

    // WebSocket message handler
    const handleMessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'bars') {
        const newBar = {
          time: message.data.timestamp,
          open: message.data.open,
          high: message.data.high,
          low: message.data.low,
          close: message.data.close
        };

        setHistoricalData(prevData => {
          // Check if we should update the last bar or add a new one
          const lastBar = prevData[prevData.length - 1];
          if (lastBar && lastBar.time === newBar.time) {
            // Update the last bar
            const updatedData = [...prevData];
            updatedData[updatedData.length - 1] = newBar;
            return updatedData;
          } else {
            // Add new bar
            return [...prevData, newBar];
          }
        });
      }
    };

    if (ws) {
      ws.addEventListener('message', handleMessage);
    }

    // Cleanup
    return () => {
      if (ws) {
        ws.removeEventListener('message', handleMessage);
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({
            type: 'unsubscribe_bars',
            asset_id: asset
          }));
        }
      }
    };
  }, [asset, ws, isConnected]);

  

  return (
    <div style={{ background: '#000', color: '#DDD',minHeight: '100vh',padding: '20px'}}>
      <h1>Asset Id: {asset}</h1>
      <h2 style={{ color: '#DDD' }}>Price Chart</h2>
      <BarChart 
        data={historicalData} 
        timeScale="1M"
        chartType="candlestick"
      />
    </div>
  );
}

export default Asset;