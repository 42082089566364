import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';

const LineChart = ({ 
  width = 800, 
  height = 400, 
  data,
  timeScale = '1D'
}) => {
  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width,
        height,
        layout: {
          backgroundColor: '#ffffff',
          textColor: '#333',
        },
        grid: {
          vertLines: { color: 'rgba(197, 203, 206, 0.5)' },
          horzLines: { color: 'rgba(197, 203, 206, 0.5)' },
        },
        crosshair: {
          mode: CrosshairMode.Normal,
        },
        timeScale: {
          timeVisible: timeScale === '1S' || timeScale === '1M',
          secondsVisible: timeScale === '1S',
        }
      });

      const lineSeries = chart.addLineSeries();

      // Format data based on timeScale
      const formattedData = data.map(item => {
        const timestamp = new Date(item.time).getTime() / 1000;
        
        if (timeScale === '1S' || timeScale === '1M') {
          // For intraday data (seconds/minutes), use UTC timestamp
          return {
            time: timestamp,
            value: item.value
          };
        } else {
          // For daily data, use YYYY-MM-DD format
          const date = new Date(item.time);
          return {
            time: date.toISOString().split('T')[0],
            value: item.value
          };
        }
      });

      // Sort data by timestamp
      formattedData.sort((a, b) => a.time - b.time);

      console.log('TimeScale:', timeScale);
      console.log('Formatted Data:', formattedData);

      lineSeries.setData(formattedData);
      chart.timeScale().fitContent();

      return () => {
        chart.remove();
      };
    }
  }, [data, width, height, timeScale]);

  return <div ref={chartContainerRef} />;
};

export default LineChart;