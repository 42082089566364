import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { WebSocketProvider } from './contexts/WebSocketContext';

import Navbar from './components/Navbar';
import Home from './pages/Home';
import Asset from './pages/Asset';
import NotFound from './pages/NotFound';

function App() {
  return (
    <WebSocketProvider>
      <Router>
        {/* Navigation */}
        <Navbar />

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/asset/:asset" element={<Asset />} />
          <Route path="*" element={<NotFound />} /> {/* 404 page */}
        </Routes>
      </Router>
    </WebSocketProvider>
  );
}

export default App;
