import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useWebSocket } from '../contexts/WebSocketContext';

function Navbar() {
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const { isConnected } = useWebSocket();
    
    return <>
        <nav className="navbar">
            <div className="nav-brand">
                <div className="connection-status">
                    <span className={`status-dot ${isConnected ? 'connected' : 'disconnected'}`}></span>
                </div>
                <Link to="/">prices.now</Link>
            </div>
            <div className="nav-links">
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
            </div>
            <div className={`nav-search ${isSearchFocused ? 'focused' : ''}`}>
                <input
                    type="text"
                    placeholder="Search tokens..."
                    className="search-input"
                    onFocus={() => setIsSearchFocused(true)}
                    onBlur={() => setIsSearchFocused(false)}
                />
                <svg 
                    className="search-icon" 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="20" 
                    height="20" 
                    viewBox="0 0 24 24" 
                    fill="none" 
                    stroke="currentColor" 
                    strokeWidth="2" 
                    strokeLinecap="round" 
                    strokeLinejoin="round"
                >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
            </div>
        </nav>
    </>;
}

export default Navbar;