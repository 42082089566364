import { useWebSocket } from '../contexts/WebSocketContext';

function TabledPriceDisplay({ symbol, quote, source }) {
    const { getPrice } = useWebSocket();
    const price = getPrice(symbol, quote, source);

    return (
        <tr>
            <td>{symbol}</td>
            <td>{quote}</td>
            <td>{source}</td>
            <td>{price ? (
                <span>{price.toFixed(10)}</span>
            ) : (
                <span>Loading...</span>
            )}</td>
        </tr>
    );
}

export default TabledPriceDisplay;