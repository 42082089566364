import React, { useEffect, useRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';

const BarChart = ({ 
    width = 1600, 
    height = 800, 
    data,
    timeScale = '1D',
    chartType = 'candlestick' // or 'bar'
  }) => {
    const chartContainerRef = useRef(null);

      useEffect(() => {
        
        if (chartContainerRef.current) {
          const chart = createChart(chartContainerRef.current, {
            width,
            height,
            layout: {
              background: { color: '#000' },
              textColor: '#DDD',
            },
            grid: {
              vertLines: { color: 'rgba(70, 70, 70, 0.5)' },
              horzLines: { color: 'rgba(70, 70, 70, 0.5)' },
            },
            crosshair: {
              mode: CrosshairMode.Normal,
              vertLine: {
                color: '#555',
                width: 1,
                style: 0,
                labelBackgroundColor: '#1E1E1E',
              },
              horzLine: {
                color: '#555',
                width: 1,
                style: 0,
                labelBackgroundColor: '#1E1E1E',
              },
            },
            timeScale: {
              timeVisible: timeScale === '1S' || timeScale === '1M',
              secondsVisible: timeScale === '1S',
              borderColor: '#333',
              textColor: '#DDD',
            },
            rightPriceScale: {
              borderColor: '#333',
              textColor: '#DDD',
            }
          });

          const series = chartType === 'candlestick' 
          ? chart.addCandlestickSeries({
              upColor: '#26a69a',      // GREEN for up (close > open)
              downColor: '#ef5350',    // RED for down (close < open)
              borderVisible: false,
              wickUpColor: '#26a69a',  // GREEN wick for up
              wickDownColor: '#ef5350', // RED wick for down
              borderUpColor: '#26a69a', // GREEN border for up
              borderDownColor: '#ef5350' // RED border for down
            })
          : chart.addBarSeries({
              upColor: '#26a69a',      // GREEN for up (close > open)
              downColor: '#ef5350',    // RED for down (close < open)
            });

        const uniqueDataMap = new Map();

        if (data) {
          data.forEach(item => {
            const timestamp = Math.floor(item.time);
            uniqueDataMap.set(timestamp, {
              time: timeScale === '1S' || timeScale === '1M' 
                ? timestamp 
                : new Date(item.time).toISOString().split('T')[0],
              open: parseFloat(item.open),
              high: parseFloat(item.high),
              low: parseFloat(item.low),
              close: parseFloat(item.close)
            });
          });
        }

      // Convert Map to array and sort by timestamp
      const formattedData = Array.from(uniqueDataMap.values())
        .sort((a, b) => {
          if (typeof a.time === 'string' && typeof b.time === 'string') {
            return a.time.localeCompare(b.time);
          }
          return a.time - b.time;
        });

      series.setData(formattedData);
      chart.timeScale().fitContent();

      return () => {
        chart.remove();
      };
    }
  }, [data, width, height, timeScale, chartType]);

  return (
    <div style={{ background: '#000', padding: '20px', borderRadius: '4px', width: 'min-content', border: '1px solid #DDD' }}>
      <div ref={chartContainerRef} />
    </div>
  );
};

export default BarChart;