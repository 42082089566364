import React from 'react';
import { useNavigate } from 'react-router-dom';
import TabledPriceDisplay from '../components/TabledPriceDisplay';

function Home() {
  const navigate = useNavigate(); // Hook for programmatic navigation

  return (
    <div>
      <h2>Current Prices</h2>
      <table>
        <thead>
            <tr>
                <th>Symbol</th>
                <th>Quote</th>
                <th>Source</th>
                <th>Price</th>
            </tr>
        </thead>
        <tbody>
          <TabledPriceDisplay symbol="BTC" quote="WBTC-USDC" source="Meteora" />
          <TabledPriceDisplay symbol="BTC" quote="USDC-WBTC" source="Meteora" />
          <TabledPriceDisplay symbol="ETH" quote="WETH-USDC" source="Orca" />
          <TabledPriceDisplay symbol="ETH" quote="WETH-WSOL" source="Orca" />
          <TabledPriceDisplay symbol="ETH" quote="WSOL-WETH" source="Orca" />
          <TabledPriceDisplay symbol="SOL" quote="WSOL-USDC" source="Raydium" />
          <TabledPriceDisplay symbol="SOL" quote="USDC-WSOL" source="Raydium" />
          <TabledPriceDisplay symbol="BONK" quote="WSOL-BONK" source="Raydium" />
          <TabledPriceDisplay symbol="BONK" quote="BONK-WSOL" source="Raydium" />
          <TabledPriceDisplay symbol="BONK" quote="BONK-USDC" source="Raydium" />
          <TabledPriceDisplay symbol="HNT" quote="HNT-WSOL" source="Lifinity" />
          <TabledPriceDisplay symbol="HNT" quote="WSOL-HNT" source="Lifinity" />
          <TabledPriceDisplay symbol="HNT" quote="HNT-USDC" source="Lifinity" />
          <TabledPriceDisplay symbol="jacky" quote="WSOL-jacky" source="Pump.fun" />
          <TabledPriceDisplay symbol="jacky" quote="jacky-WSOL" source="Pump.fun" />
        </tbody>
      </table>
    </div>
  );
}

export default Home;