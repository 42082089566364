import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
    // Price state with nested structure matching backend
    const [prices, setPrices] = useState({});
    const [ws, setWs] = useState(null);
    const [isConnected, setIsConnected] = useState(false);

    const updatePrices = useCallback((newData) => {
        console.log('Received new price data:', newData);
        setPrices(currentPrices => {
            const updatedPrices = { ...currentPrices };
            
            // Update only the changed symbols/pairs
            Object.entries(newData).forEach(([symbol, quotePairs]) => {
                if (!updatedPrices[symbol]) {
                    updatedPrices[symbol] = {};
                }
                
                Object.entries(quotePairs).forEach(([quote, sources]) => {
                    if (!updatedPrices[symbol][quote]) {
                        updatedPrices[symbol][quote] = {};
                    }
                    
                    Object.entries(sources).forEach(([source, price]) => {
                        // Only update if price has changed
                        if (updatedPrices[symbol][quote][source] !== price) {
                            updatedPrices[symbol][quote][source] = price;
                            console.log(`Updated ${symbol}/${quote} from ${source}: ${price}`);
                        }
                    });
                });
            });

            return updatedPrices;
        });
    }, []);

    const connect = useCallback(() => {
        const socket = new WebSocket(`wss://prices.now/ws`);

        socket.onopen = () => {
            console.log('Connected to WebSocket');
            setIsConnected(true);
        };

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                if (data.type === 'prices') {
                    updatePrices(data.data);
                }
            } catch (error) {
                console.error('Failed to parse message:', error);
            }
        };

        socket.onclose = () => {
            console.log('Disconnected from WebSocket');
            setIsConnected(false);
            // Attempt to reconnect after 5 seconds
            setTimeout(connect, 5000);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            socket.close();
        };

        setWs(socket);
    }, []);

    useEffect(() => {
        connect();
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [connect]);

    const sendMessage = (message) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(message));
          return true;
        }
        return false;
    };

    // Helper functions to access price data
    const getPrice = useCallback((symbol, quote = null, source = null) => {
        if (!prices[symbol]) return null;
        if (quote && !prices[symbol][quote]) return null;
        if (source && quote && !prices[symbol][quote][source]) return null;

        if (quote && source) return prices[symbol][quote][source];
        if (quote) return prices[symbol][quote];
        return prices[symbol];
    }, [prices]);

    return (
        <WebSocketContext.Provider value={{ 
            ws, prices, 
            isConnected,
            reconnect: connect,
            getPrice, // Expose helper function
            sendMessage,
        }}>
            {children}
        </WebSocketContext.Provider>
    );
}

// Custom hook to use the WebSocket context
export function useWebSocket() {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
}

